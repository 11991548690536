<template>
  <div class="logo-container">
    <div class="logo">
      <img src="@/assets/logo.svg" alt="">
      <span>Säkerhets-SM 2025 Kval</span>
    </div>
  </div>
  <ClockTimeline />
  <div class="button-container">
    <router-link
      v-if="!$store.state.archived"
      :to="{ name: 'signin' }"
    >
      <button class="button">
        Register!
      </button>
    </router-link>
  </div>
  <div class="content">
    <div>
      <h3>Description</h3>
      <p>
        Säkerhets-SM is a national Capture The Flag competition for high schools students. The competition focuses on problem solving in the areas of programming, cryptography, binary exploitation, reverse engineering, web security and forensics. The tasks range from beginner-friendly to really tricky, so even if you've never participated in a CTF before, you can take part. Above all, you will learn a lot from participating.
      </p>
      <p>
        CTF (Capture The Flag) competitions involve getting access to a set of challenges at the start of the competition and demonstrating that you have solved a task by submitting the task's "flag" to the platform. Flags in this competition are text strings of the form <code>SSM{...}</code>.
      </p>
      <p>
        This year's competition consists of a qualifying round and a final. The qualifying round will take place here from January 17th 20:00 CET to January 19th 20:00 CET. The competition will thus last for 48 hours, but it is already possible to register your team!
      </p>
      <p>
        Some of the challenges in the qualifying round are marked as easy. We recommend you to start with them if you haven't played a CTF competition before! We also allow you to ask the organizers for clues on these easy challenges, so don't hesitate to send a message to the challenge author via <a href="https://discord.gg/m6pcEYp">Discord</a> or post a message in #säkerhetssm if you get stuck!
      </p>
      <h3>Final</h3>
      <p>
        The best teams qualify for the final which this year is held at KTH in Stockholm! This will take place March 7th-9th, and we pay for any travel, accommodation and food costs. The qualification cutoff will be decided after the qualification round has concluded, with the intention of inviting around 45 players.
      </p>
    </div>
    <div>
      <h3>Rules</h3>
      <ul>
        <li>For a team to be in the eligible class and be able to qualify for the finals, it must be composed of at most 3 people who all must be students at a compulsory school or high school (grundskolan eller gymnasiet) in Sweden.</li>
        <li>If any of the requirements in the previous rule are not met, you can still participate in the competition in the open class.</li>
        <li>It is forbidden to be a member of multiple teams simultaneously.</li>
        <li>Unless explicitly stated otherwise, it is forbidden to discuss problem solutions and clues with individuals outside of your team while the competition is ongoing.</li>
        <li>It is allowed to search the internet, read discussions on forums, and use previously written code, but not to ask questions yourself during the competition. It is not allowed, for example, to publicly ask about the tasks in Discord.</li>
        <li>It is allowed to use AI tools such as ChatGPT or GitHub Copilot.</li>
        <li>It is forbidden to exploit bugs in or attack the CTF platform. This includes brute-forcing flags against the competition platform. Any security vulnerabilities can be reported to the organizers and may be rewarded with a bounty.</li>
        <li>
          Respect the challenge servers. Do not create unreasonably large traffic to the challenges. No tasks require sending an unreasonable amount of data to get the flag. <div class="tooltip">
            ?<span class="tooltiptext">What counts as an unreasonable amount of data?<br><br>If you see a solution to a task that requires a few thousand requests, this is completely okay to perform. If your solution requires tens or hundreds of thousands of requests, your solution is probably wrong, and you should find a better solution to not affect the server's performance while solving. It is not okay to blindly spam the server with data, for example, through a dumb scanner like dirbuster, unless it is stated in the task that it is allowed.</span>
          </div>
        </li>
        <li>It is forbidden to disrupt other people's ability to solve challenges.</li>
        <li>It is forbidden to have more than one account on the CTF platform.</li>
        <li>It is forbidden to use the platform for commercial marketing purposes.</li>
        <li>This is a competition in ethical hacking, take responsibility!</li>
        <li>The decisions of the organizers are final.</li>
      </ul>
    </div>
  </div>
  <div class="centeredcontent">
    <h3>Discord</h3>
    <p>
      Join Kodsport's <a href="https://discord.gg/m6pcEYp">Discord server</a> to discuss the competition with others in the #säkerhetssm channel! Remember that it's not allowed to discuss the problems while the competition is ongoing! If you don't have any teammates or if your team isn't full, you can also send a message in the #leta-lag channel to find people to play with. In the Discord, you'll also find all the organizers, admins, and challenge authors in case you need to contact them; they have the role @Säkerhets-SM.
    </p>
  </div>
  <h3>I samarbete med</h3>
  <div class="mainsponsorcontainer">
    <a href="https://www.fra.se/">
      <img
        class="mainsponsor"
        src="@/assets/sponsors/fra.png"
        alt="FRA - Försvarets Radioanstalt"
      >
    </a>
  </div>
  <div class="mainsponsorcontainer">
    <a
    class="top--logo"
    href="https://www.msab.com"
    title="MSAB">
    <svg width="170"
    height="50"
    viewBox="0 0 170 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M87.4823 35.0472C87.2459 32.1513 85.709 29.2553 83.3446 27.364C80.9802 25.4137 78.3203 24.468 76.3696 23.8179L75.6603 23.5815C73.5915 22.9314 71.5226 22.1631 69.9858 20.9219C69.1582 20.2718 68.5671 19.5626 68.1534 18.7352C67.6805 17.7305 67.5032 16.6666 67.6214 15.3073C68.0943 11.4657 71.5226 8.5697 75.483 8.8652C76.9607 8.98341 78.3794 9.51532 79.5025 10.4018C80.6847 11.1702 81.6304 12.1158 82.8126 13.5342L82.9308 13.7115L85.4726 8.39239L85.4135 8.33329C83.1082 5.31911 79.6798 3.42785 75.8968 3.13234C75.6012 3.13234 75.2466 3.07324 74.951 3.07324C69.2173 3.07324 64.0748 6.85575 62.4197 12.3522V12.4113C62.2424 13.0614 62.065 13.8297 61.9468 14.6572C61.8286 15.4255 61.9468 17.0212 61.9468 17.0803C62.065 18.5579 62.4197 19.8581 63.0108 21.0992C64.0748 23.3451 65.9663 25.2364 68.508 26.6548C70.4586 27.7777 72.4684 28.4279 73.7688 28.8416L74.2417 29.0189C76.3105 29.7281 78.3794 30.4964 79.7389 31.6784C81.1575 32.9196 81.926 34.2198 81.8077 36.9385C81.2758 41.312 77.3745 44.5035 72.9413 44.208C70.6951 44.0307 68.6853 43.026 67.2076 41.312C66.1436 40.3664 64.7841 38.6524 64.2521 38.0023L64.1339 37.825L61.5922 43.2624L61.6513 43.3215C64.0157 47.104 68.0943 49.5862 72.5866 49.8818C72.9413 49.8818 73.2959 49.9409 73.5915 49.9409C80.6255 49.9409 86.5957 44.6808 87.5414 37.7068V37.4704C87.6005 36.9385 87.4823 35.1063 87.4823 35.0472Z" fill="white"></path>
    <path d="M169.705 33.0969C168.936 29.5508 166.808 26.714 163.853 24.8818C165.862 23.1679 167.34 20.8038 167.813 18.0851C167.931 17.3759 167.99 16.6076 167.99 15.8392C167.99 14.9527 167.872 14.1253 167.695 13.2388C167.045 10.3428 165.39 7.86053 163.025 6.20568C160.72 4.55083 157.764 3.66431 154.572 3.66431H142.041V49.1135H155.459C158.947 49.0544 162.198 48.0497 164.68 46.2175C167.281 44.3263 169.113 41.5485 169.705 38.2979C169.823 37.4705 169.941 36.7021 169.941 35.8747C170 34.9291 169.882 33.9835 169.705 33.0969ZM164.503 37.3523C164.148 39.2435 163.143 40.7802 161.547 41.9622C160.011 43.0851 157.823 43.7943 155.341 43.7943H147.479V29.7872V27.896V22.5177V18.9125V9.04256H154.691C156.878 9.04256 158.71 9.63357 160.011 10.5792C161.311 11.5248 162.198 12.766 162.552 14.4208C162.67 14.8936 162.73 15.3664 162.73 15.8392C162.73 16.253 162.67 16.6667 162.611 17.0804C162.02 20.0355 159.597 22.2222 155.696 22.5768H152.208V27.9551H155.755C158.119 28.0142 160.129 28.7234 161.607 29.8463C163.084 30.9693 164.089 32.4468 164.503 34.279C164.621 34.8109 164.68 35.4019 164.68 35.9338C164.621 36.4066 164.562 36.8794 164.503 37.3523Z" fill="white"></path>
    <path d="M101.492 49.1726L114.082 16.0757L126.673 49.1726H132.643L114.082 0L95.5216 49.1726H101.492Z" fill="white"></path>
    <path d="M5.61544 49.1726L16.0188 16.9031L26.7768 47.8132L37.4757 16.9031L47.879 49.1726H53.5536L37.653 0L26.7768 31.4421L15.8414 0L0 49.1726H5.61544Z" fill="white"></path>
    </svg>
    </a>
  </div>
  <p style="text-align: center">
    Kodsport's infrastructure is sponsored by <a href="https://glesys.com/">GleSYS</a>
  </p>
  <p style="text-align: center">
    <a href="/privacy-policy.html">Privacy Policy</a>
  </p>
</template>

<script>
import ClockTimeline from '@/components/ClockTimeline.vue'

export default {
    name: 'HomeView',
    components: {
        ClockTimeline,
    },
}
</script>

<style lang="scss" scoped>
.logo-container {
  .logo {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-align: center;
    max-width: 50rem;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2rem;
      padding: 0 1rem;
      word-wrap: normal;
    }
  }
}

h1, h3 {
  text-align: center;
}

p {
  padding: 0 10px 0 10px;
}

.button-container {
  margin-top: 2rem;
  text-align: center;
}

.button {
  padding: 0.6rem 1rem;
  font-size: 2rem;
  color: #ffab1c;
}

.content {
  display: grid;
  grid-template-columns: 50% 50%;
  padding-top: 3rem;
}

.centeredcontent {
  width: 50%;
  margin: 0 auto 3rem auto;
}

.mainsponsorcontainer {
  text-align: center;
  margin-top: 2rem;
}

.mainsponsor {
  max-width: 100%;
  max-height: 350px;
  padding-bottom: 1rem;
}

.sponsorcontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.sponsor {
  max-width: 90%;
  max-height: 100px;
  margin: 1rem 1rem;
}

@media only screen and (max-width: 990px) {
  .content {
    display: block;
  }

  .centeredcontent {
    width: auto;
  }
}
</style>
